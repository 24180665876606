/*** FONTS ***/
@font-face {
  font-family: Inter_bold;
  src: url(../assets/fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter_medium;
  src: url(../assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter_regular;
  src: url(../assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter_semiBold;
  src: url(../assets/fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter_light;
  src: url(../assets/fonts/Inter-Light.ttf);
}

body {
  margin: 0;
  padding: 0;
}

.css-7053f3-MuiInputBase-input-MuiOutlinedInput-input{
  height: 16px !important;
}

/* .resourcesNavLink {
  text-decoration: none;
  padding:13px 35px 13px 0px;
  position: relative;
  white-space: nowrap;
}

.resourcesNavLink .resourcesNavLinkText{
  opacity: 0.5;
}

.resourcesNavLink.active .resourcesNavLinkText{
  opacity: 1;
  color: #0052CC;
}


.resourcesNavLink.active #borderBottom{
  border-bottom: 3px solid #0052CC;
  padding-bottom: 10px;
  position: absolute;
  right: 50%;
  left: 0;
} */

.masterDataNavLink{
  text-decoration: none;
  padding:13px 35px 8px 0px;
  position: relative;
  white-space: nowrap;
}

.masterDataNavLink .masterDataNavLinkText{
  opacity: 0.5;
}

.masterDataNavLink.active .masterDataNavLinkText{
  opacity: 1;
  color: #0052CC;
}


.masterDataNavLink.active #borderBottom{
  border-bottom: 3px solid #0052CC;
  padding-bottom: 6px;
  position: absolute;
  right: 50%;
  left: 0;
}

